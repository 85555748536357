






























import { mapGetters } from 'vuex'
import mixins from 'vue-typed-mixins'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'
export default mixins(DraftElement).extend({
  name: 'AppFooterMenu',
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters({
      draftPages: 'builder/getDraftPages'
    }),
    menuList (): Record<string, any> {
      return [
        {
          name: this.data.configuration.wb_menu_title__text__,
          key: 'footer-menu',
          items: [...this.pageListForGroup(this.data.configuration.wb_menu_column_1__page_ids__)]
        },
        {
          name: '',
          key: 'footer-menu2',
          items: [...this.pageListForGroup(this.data.configuration.wb_menu_column_2__page_ids__)]
        }
      ]
    }
  },
  methods: {

  }
})
